
import ASkeletonLoader from 'theme/components/atoms/loaders/a-skeleton-loader.vue';
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'MHeroBannerSkeleton',
  components: {
    ASkeletonLoader
  },
  props: {
    deviceInfo: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const isMobile = ref(props.deviceInfo.isMobile);

    const height = computed(() => {
      return isMobile.value ? '230px' : '380px';
    });

    return {
      isMobile,
      height
    };
  }
});
