<template>
  <OSection
    :title-heading="$t('My lists')"
    is-centered
  >
    <template #link>
      <SfLink
        :link="localizedRoute('/my-account/wishlist')"
        class="home-favorites__heading-link sf-link--primary"
      >
        {{ $t('All goods') }}
      </SfLink>
    </template>
    <template #tags>
      <MProductTags
        :tags="notEmptyWishlists"
        @setActiveCategory="setActiveWishlist"
        :is-it-have-a-counter="true"
      />
    </template>
    <MProductCarousel
      v-if="productsInCurrentWishlist.length"
      list-id="home_wishlist"
      :list-name="$t('My lists')"
      :products="productsInCurrentWishlist"
      :key="productsInCurrentWishlist.length"
      arrow-color="gray"
      is-white-card
    />
  </OSection>
</template>

<script>
import { SfLink } from '@storefront-ui/vue';
import MProductCarousel from 'theme/components/molecules/m-product-carousel.vue';
import OSection from 'theme/components/organisms/o-section.vue';
import { mapGetters } from 'vuex';
import MProductTags from 'theme/components/molecules/m-product-tags.vue';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  name: 'OHomeFavorites',
  data () {
    return {
      activeTagIndex: 0
    }
  },
  components: {
    OSection,
    MProductCarousel,
    SfLink,
    MProductTags
  },
  computed: {
    ...mapGetters({
      getWishlists: 'wishlist/getWishlists',
      getWishlistItems: 'wishlist/getWishlistItems'
    }),
    productsInCurrentWishlist () {
      const currentWishlist = this.notEmptyWishlists[this.activeTagIndex]
      const allProducts = this.getWishlistItems

      if (!currentWishlist?.items?.length || !allProducts?.length) {
        return []
      }

      return currentWishlist.items
        .map(item => allProducts.find(product => product.id === item.product_id))
        .filter(Boolean)
    },
    wishlists () {
      return this.getWishlists.map(wishlist => ({
        ...wishlist,
        name: wishlist.name === 'Default' ? this.getDefaultWishlistName() : wishlist.name
      }));
    },
    notEmptyWishlists () {
      return this.wishlists.filter(wishlist => wishlist.items.length > 0)
    },
    locale () {
      return currentStoreView().i18n.defaultLocale;
    }
  },
  methods: {
    getDefaultWishlistName () {
      if (this.locale === 'uk-UA') {
        return 'Обране'
      } else if (this.locale === 'ru-RU') {
        return 'Избранное'
      } else {
        return 'Favorites'
      }
    },
    setActiveWishlist (index) {
      this.activeTagIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.home-favorites {
  --section-padding: 0 0 50px;

  @include for-desktop {
    --section-padding: 0;
  }
}

.home-favorites__heading-link {
  @media screen and (min-width: 1024px) {
    white-space: nowrap;
    padding: 0 0 0 1.5rem;
  }
}
::v-deep {
  .m-product-tags {
    @include for-mobile {
      padding-left: var(--spacer-10);
    }
  }
  .sf-heading__title {
    flex-shrink: 0;
  }
  .sf-carousel__controls {
    --carousel-controls-top: -90px;
  }
}
</style>
