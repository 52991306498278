<template>
  <div class="m-product-tags">
    <div class="m-product-tags__list" v-if="tags.length">
      <div
        v-if="isAllTagsRequired"
        class="m-product-tags__link"
        :class="{
          'm-product-tags__link--active': activeTagIndex === -1,
          'm-product-tags__link--prev': activeTagIndex === 1
        }"
        @click="setActiveCategory(-1)"
      >
        <span>{{ $t('All') }}</span>
      </div>
      <div
        v-for="(tag, i) in tags"
        :key="i"
        class="m-product-tags__link"
        :class="{
          'm-product-tags__link--active': activeTagIndex === i,
          'm-product-tags__link--prev': activeTagIndex === i + 1
        }"
        @click="setActiveCategory(i)"
      >
        <template v-if="isItHaveACounter">
          <span>{{ tag.name }} ({{ tag.items.length }})</span>
        </template>
        <template v-else>
          <span>{{ tag.name }}</span>
        </template>
      </div>
    </div>
    <div class="m-product-tags__list" v-else>
      <SfLink class="m-product-tags__link m-product-tags__link--active" link="#">
        <span>Tag</span>
      </SfLink>
      <SfLink class="m-product-tags__link" link="#">
        <span>Tag</span>
      </SfLink>
      <SfLink class="m-product-tags__link" link="#">
        <span>Tag</span>
      </SfLink>
    </div>
  </div>
</template>
<script>
import {
  SfLink
} from '@storefront-ui/vue';
export default {
  name: 'MProductTags',
  components: {
    SfLink
  },
  props: {
    tags: {
      type: [Array, Object],
      default: () => []
    },
    isAllTagsRequired: {
      type: Boolean,
      default: false
    },
    isItHaveACounter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeTagIndex: this.isAllTagsRequired ? -1 : 0
    };
  },
  methods: {
    setActiveCategory (index) {
      this.activeTagIndex = index;
      this.$emit('setActiveCategory', index)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-product-tags {
  order: 3;
  margin: 0 calc(-1 * var(--spacer-10)) 15px;
  overflow-y: hidden;
  width: auto;
  padding-bottom: var(--spacer-5);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: $desktop-min) {
    order: unset;
    padding-bottom: 0;
    margin: 0 0 15px;
  }

  &__list {
    display: inline-flex;
    overflow-x: auto;
    gap: 10px;
  }

  &__link{
    position: relative;
    padding: 10px;
    border-radius: 7px;
    color: var(--black);
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--grey, #E4E4E5);
    background: rgba(255, 255, 255, 0.50);

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.sf-link{
      color: var(--black);
    }

    &--active{
      color: var(--orange);
      cursor: default;
      margin-left: -1px;
      border: 1px solid var(--orange);
      background: var(--orange-light-background, #FDF0ED);

      &:first-child {
        margin-left: 0;
      }

      &.sf-link{
        color: var(--orange);
      }
    }
  }
}
</style>
